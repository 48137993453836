import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { withPreview } from 'gatsby-source-prismic-graphql';

import { relatedLinkProps, contentSerializer } from '../../../utils';

import { linkFragmentQuery } from '../../../templates/page';

import ColumnText from '../../ColumnText';
import ImageGrid from '../../ImageGrid';
import Section from '../Section';

import styles from './TechSection.module.scss';

const TechSection = ({ prismic }) => {
  const sectionData = prismic.allHomepages.edges.slice(0, 1).pop();

  if (!sectionData) {
    return null;
  }

  const { photos, related_link_tech, tech_body: body, tech_headline: headline } = sectionData.node;
  const photoGroup = photos.slice(0, 1).pop();

  return (
    <Section title={RichText.asText(headline)} {...relatedLinkProps(related_link_tech)}>
      <div className={styles.body}>
        {photoGroup && (
          <ImageGrid
            flipOrientation
            photos={{
              feature_photo: photoGroup.feature_photo,
              feature_photoSharp: photoGroup.feature_photoSharp,
              thumbnail_one: photoGroup.thumbnail_photo,
              thumbnail_oneSharp: photoGroup.thumbnail_photoSharp,
              thumbnail_two: photoGroup.rendering_photo,
            }}
            overflowLastImage
          />
        )}

        <div className={styles.columnText}>
          <ColumnText>
            <RichText render={body} htmlSerializer={contentSerializer} />
          </ColumnText>
        </div>
      </div>
    </Section>
  );
};

TechSection.propTypes = {
  prismic: PropTypes.shape({
    allHomepages: PropTypes.object,
  }),
};

export const techSectionFrag = graphql`
  fragment HomepageTechSection on PRISMIC_Homepage {
    tech_headline
    tech_body
    photos {
      feature_photo
      thumbnail_photo
      rendering_photo
    }

    related_link_tech {
      label
      link {
        ...LinkQuery
      }
    }
  }
`;

export const techSectionQuery = graphql`
  query HomepageTechSectionQuery {
    prismic {
      allHomepages {
        edges {
          node {
            ...HomepageTechSection

            photos {
              feature_photoSharp {
                ...FeaturePhotoQuery
              }
              thumbnail_photoSharp {
                ...ThumbnailQuery
              }
              rendering_photoSharp {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// This query is the same, just without the Sharp transform fields. Since the image transforms
// from the gatsby-source-prismic-graphql plugin happens after the initial schema gets generated,
// the previewed StaticQuery won't get that field directly from the API and will error out, saying
// that you can't query that field.
// See https://github.com/birkir/gatsby-source-prismic-graphql#working-with-gatsby-image for more.
export const techSectionPreviewQuery = graphql`
  query HomepageTechSectionPreviewQuery {
    prismic {
      allHomepages {
        edges {
          node {
            ...HomepageTechSection
          }
        }
      }
    }
  }
`;

const TechSectionWithQuery = props => (
  <StaticQuery
    query={techSectionQuery}
    render={withPreview(
      data => (
        <TechSection {...data} {...props} />
      ),
      techSectionPreviewQuery,
      [linkFragmentQuery, techSectionFrag],
    )}
  />
);

export default TechSectionWithQuery;
