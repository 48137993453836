import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

import styles from './Values.module.scss';

const Values = ({ values }) => {
  if (!values || values.length === 0) {
    return null;
  }

  return (
    <ul className={styles.values}>
      {values.map(({ value, description }, idx) => (
        <li key={`value-${idx}`} className={styles.value}>
          <h5 className={styles.value__title}>{value}</h5>
          <div className={styles.value__desc}>
            <RichText render={description} />
          </div>
        </li>
      ))}
    </ul>
  );
};

Values.defaultProps = {
  values: [],
};

Values.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      description: PropTypes.array,
    }),
  ),
};

export default Values;
