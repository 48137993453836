import React from 'react';
import * as PropTypes from 'prop-types';

import Button from '../../../Button';

import styles from './Cta.module.scss';

const Cta = ({ ctaUrl, ctaText }) => (
  <div className={styles.cta}>
    <div className={styles.button}>
      <Button url={ctaUrl} text={ctaText} />
    </div>
  </div>
);

Cta.propTypes = {
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
};

export default Cta;
