import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { withPreview } from 'gatsby-source-prismic-graphql';
import { throttle } from 'throttle-debounce';

import { className, getImgUrl, isMobile, isTouch } from '../../../utils';

import styles from './Topper.module.scss';

const Topper = ({ prismic }) => {
  // Data
  const topperData = prismic.allHomepages.edges.slice(0, 1).pop();

  // Fallback, in case of bad data.
  if (!topperData) {
    return null;
  }

  const { headline, benefits: allBenefits } = topperData.node;
  const benefits = allBenefits.slice(0, 3); // Limit to the first three benefits.

  // State
  const [activeValueIndex, setActiveBenefitIndex] = useState(0);
  const [cursorVisible, setCursorVisible] = useState(false);
  const topperEl = useRef(null);
  const cursorEl = useRef(null);
  const shadowEl = useRef(null);

  const trackCursor = event => {
    const cursorPos = `transform: translate3d( ${event.clientX}px, ${event.clientY}px, 0 )`;
    if (cursorEl && cursorEl.current) {
      cursorEl.current.style.cssText = cursorPos;
    }
    if (shadowEl && shadowEl.current) {
      shadowEl.current.style.cssText = cursorPos;
    }
  };

  const handleEnterTopper = () => {
    setCursorVisible(true);
    document.body.addEventListener('mousemove', throttle(10, trackCursor));
  };

  const handleLeaveTopper = () => {
    setCursorVisible(false);
    document.body.removeEventListener('mousemove', trackCursor);
  };

  useEffect(() => {
    if (isMobile() || isTouch() || !topperEl || !topperEl.current) {
      return;
    }

    topperEl.current.addEventListener('mouseenter', handleEnterTopper);
    topperEl.current.addEventListener('mouseleave', handleLeaveTopper);

    return () => {
      topperEl.current.removeEventListener('mouseenter', handleEnterTopper);
      topperEl.current.removeEventListener('mouseleave', handleLeaveTopper);
      topperEl.current.removeEventListener('mousemove', trackCursor);
    };
  }, []);

  useEffect(() => {
    if (
      !isMobile() ||
      !isTouch() ||
      !topperEl ||
      !topperEl.current ||
      !window.DeviceOrientationEvent
    ) {
      return;
    }
  });

  return (
    <div className={styles.topper} ref={topperEl}>
      <div
        {...className(
          styles.cursor,
          isTouch() && styles.noCursor,
          cursorVisible && styles.cursorVisible,
          activeValueIndex > 0 ? styles.cursorOnLight : styles.cursorOnDark,
        )}
        ref={cursorEl}>
        <div className={styles.cursor__circle} />
      </div>
      <div ref={shadowEl} className={styles.cursor__shadow} />

      <div className={styles.backgroundImages}>
        {benefits &&
          benefits.length > 0 &&
          benefits.map(({ background_image, background_imageSharp }, idx) => (
            <figure
              key={`benefit-bg-image-${idx}`}
              {...className(
                styles.backgroundImage,
                activeValueIndex === idx && styles.backgroundImageActive,
              )}
              style={{
                backgroundImage: `url(${getImgUrl(background_image, background_imageSharp)})`,
              }}
            />
          ))}
      </div>

      <div className={styles.content}>
        {headline && (
          <div className={styles.header}>
            <h1 className={styles.title}>{RichText.asText(headline)}</h1>
          </div>
        )}

        <div className={styles.benefits}>
          {benefits &&
            benefits.length > 0 &&
            benefits.map(({ benefit, description }, idx) => (
              <div
                key={`benefit-description-${idx}`}
                {...className(styles.benefit, activeValueIndex === idx && styles.benefitActive)}
                onMouseEnter={() => setActiveBenefitIndex(idx)}>
                <h5 className={styles.benefit__header}>{benefit}</h5>
                <div className={styles.benefit__body}>
                  <RichText render={description} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

Topper.propTypes = {
  prismic: PropTypes.shape({
    allHomepages: PropTypes.object,
  }),
};

const homepageTopperQuery = graphql`
  query HomepageTopperQuery {
    prismic {
      allHomepages {
        edges {
          node {
            headline
            benefits {
              benefit
              description
              background_image
              background_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const homepageTopperQueryPreview = graphql`
  query HomepageTopperQuery {
    prismic {
      allHomepages {
        edges {
          node {
            headline
            benefits {
              benefit
              description
              background_image
            }
          }
        }
      }
    }
  }
`;

const TopperWithQuery = props => (
  <StaticQuery
    query={homepageTopperQuery}
    render={withPreview(
      data => (
        <Topper {...data} {...props} />
      ),
      homepageTopperQueryPreview,
    )}
  />
);

export default TopperWithQuery;
