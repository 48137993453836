import React from 'react';

const Quote = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="59" height="44" viewBox="0 0 59 44">
    <path
      fill="currentColor"
      d="M228.07156,0.80733945 L213.943119,22.2018349 L213.943119,44 L198.2,44 L198.2,21.7981651 L212.73211,0 L228.07156,0 L228.07156,0.80733945 Z M256.32844,0.80733945 L242.2,22.2018349 L242.2,44 L226.456881,44 L226.456881,21.7981651 L240.988991,0 L256.32844,0 L256.32844,0.80733945 Z M218.585321,1.6146789 L213.53945,1.6146789 L199.814679,22.2018349 L199.814679,42.3853211 L205.26422,42.3853211 L205.26422,21.7981651 L218.585321,1.6146789 Z M206.878899,22.2018349 L206.878899,42.3853211 L212.32844,42.3853211 L212.32844,21.7981651 L225.649541,1.6146789 L220.60367,1.6146789 L206.878899,22.2018349 Z M246.842202,1.6146789 L241.79633,1.6146789 L228.07156,22.2018349 L228.07156,42.3853211 L233.521101,42.3853211 L233.521101,21.7981651 L246.842202,1.6146789 Z M235.13578,22.2018349 L235.13578,42.3853211 L240.585321,42.3853211 L240.585321,21.7981651 L253.906422,1.6146789 L248.86055,1.6146789 L235.13578,22.2018349 Z"
      transform="translate(-198)"
    />
  </svg>
);

export default Quote;
