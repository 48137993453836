import React from 'react';
import * as PropTypes from 'prop-types';

import styles from './Header.module.scss';

const SectionHeader = ({ title }) => (
  <div className={styles.header}>
    <h2 className={styles.title}>{title}</h2>
  </div>
);

SectionHeader.propTypes = {
  title: PropTypes.string,
};

export default SectionHeader;
