import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import SEO from '../components/SEO';
import Topper from '../components/HomePage/Topper';
import CompanySection from '../components/HomePage/CompanySection';
import TechSection from '../components/HomePage/TechSection';
import Footer from '../components/Footer';

const IndexPage = ({ location, data }) => {
  const homePageData = data.prismic.allHomepages.edges.slice(0, 1).pop();
  const { seo } = homePageData.node;

  return (
    <>
      <SEO
        title="Home"
        description={seo && seo.description ? seo.description : undefined}
        image={seo && seo.image ? seo.image : undefined}
        href={location.href}
      />
      <Topper />
      <TechSection />
      <CompanySection />
      <Footer />
    </>
  );
};

IndexPage.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string,
  }),
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      allHomepages: PropTypes.object,
    }),
  }),
};

export const query = graphql`
  query HomePageQuery {
    prismic {
      allHomepages {
        edges {
          node {
            seo {
              ...PageSEOQuery
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
