import React from 'react';
import PropTypes from 'prop-types';

import Quote from '../../../../svgs/Quote';

import styles from './QuoteImageLockup.module.scss';

const QuoteImageLockup = ({ image, quote, author }) => (
  <figure className={styles.quoteImageLockup}>
    <div className={styles.imageWrapper}>
      <div className={styles.image} style={image && { backgroundImage: `url(${image})` }} />
    </div>
    <div className={styles.quote}>
      <blockquote className={styles.quote__inner}>
        <div className={styles.icon}>
          <Quote />
        </div>
        <h3 className={styles.quote__text}>{quote}</h3>
        {author && <div className={styles.quote__author}>{author}</div>}
      </blockquote>
    </div>
  </figure>
);

QuoteImageLockup.defaultProps = {
  image: null,
  author: null,
};

QuoteImageLockup.propTypes = {
  image: PropTypes.string,
  quote: PropTypes.string.isRequired,
  author: PropTypes.object,
};

export default QuoteImageLockup;
