import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { withPreview } from 'gatsby-source-prismic-graphql';

import { getImgUrl, relatedLinkProps } from '../../../utils';

import { linkFragmentQuery } from '../../../templates/page';

import Section from '../Section';

import QuoteImageLockup from './QuoteImageLockup';
import Values from './Values';

const CompanySection = ({ prismic }) => {
  const sectionData = prismic.allHomepages.edges.slice(0, 1).pop();

  if (!sectionData) {
    return null;
  }

  const {
    company_headline: headline,
    quote_lockup,
    values,
    related_link_company,
  } = sectionData.node;
  const quoteData = quote_lockup.slice(0, 1).pop();

  return (
    <Section title={RichText.asText(headline)} {...relatedLinkProps(related_link_company)}>
      {quoteData && (
        <QuoteImageLockup
          image={getImgUrl(quoteData.image, quoteData.imageSharp)}
          quote={RichText.asText(quoteData.quote)}
          author={<RichText render={quoteData.author} />}
        />
      )}

      <Values values={values} />
    </Section>
  );
};

CompanySection.propTypes = {
  prismic: PropTypes.shape({
    allHomepages: PropTypes.object,
  }),
};

export const companySectionFrag = graphql`
  fragment HomepageCompanySection on PRISMIC_Homepage {
    company_headline
    quote_lockup {
      image
      quote
      author
    }
    values {
      value
      description
    }

    related_link_company {
      label
      link {
        ...LinkQuery
      }
    }
  }
`;

export const companySectionQuery = graphql`
  query HomepageCompanySectionQuery {
    prismic {
      allHomepages {
        edges {
          node {
            ...HomepageCompanySection

            quote_lockup {
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const companySectionPreviewQuery = graphql`
  query HomepageCompanySectionPreviewQuery {
    prismic {
      allHomepages {
        edges {
          node {
            ...HomepageCompanySection
          }
        }
      }
    }
  }
`;

const CompanySectionWithQuery = props => (
  <StaticQuery
    query={companySectionQuery}
    render={withPreview(
      data => (
        <CompanySection {...data} {...props} />
      ),
      companySectionPreviewQuery,
      [linkFragmentQuery, companySectionFrag],
    )}
  />
);

export default CompanySectionWithQuery;
