import React from 'react';
import * as PropTypes from 'prop-types';

import Cta from './Cta';
import Header from './Header';

import styles from './Section.module.scss';

const Section = ({ title, ctaUrl, ctaText, children }) => (
  <div className={styles.section}>
    <div className={styles.sectionInner}>
      <Header title={title} />
      {children}
      <Cta ctaUrl={ctaUrl} ctaText={ctaText} />
    </div>
  </div>
);

Section.propTypes = {
  title: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
  children: PropTypes.node,
};

export default Section;
